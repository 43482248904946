@media screen and (min-width: 700px) {
  .contactButton:hover {
    background-color: #49d68f;
    font-family: "Montserrat", "Courier New", Courier, monospace;
    font-size: 1.8rem;
    color: #fff;
    border-radius: 16px;
    border-style: solid;
    width: 18rem;
    height: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 2vh;
  }

  .introSnippet {
    background-color: #3e42c2;
    margin-top: 8%;
    width: 25vw;
    border-radius: 16px;
    color: white;
    justify-content: center;
    align-self: center;
  }

  .magic {
    color: hwb(3 44% 5%);
  }

  div {
    display: block;
    margin: auto;
    padding: auto;
  }

  li {
    list-style-type: none;
  }

  .cardContainer {
    width: 100%;
    box-shadow: 0px 10px 20px 5px rgba(81, 20, 141, 0.5);
  }
}

@media screen and (max-width: 699px) {
  .contactButton {
    background-color: #ffe36d;
    font-family: "Montserrat", "Courier New", Courier, monospace;
    font-size: 1.2rem;
    border-radius: 16px;
    border-style: solid;
    width: 15rem;
    height: 2rem;
    text-transform: uppercase;
    margin-bottom: 3vh;
    align-self: center;
  }

  .contactButton:hover {
    background-color: #49d68f;
    color: #fff;
    font-family: "Montserrat", "Courier New", Courier, monospace;
    font-size: 1.2rem;
    border-radius: 16px;
    border-style: solid;
    width: 15rem;
    height: 2rem;
    text-transform: uppercase;
    margin-bottom: 3vh;
  }
}

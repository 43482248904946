@import url("https://fonts.googleapis.com/css2?family=Vast+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Nunito:ital,wght@0,900;1,600&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@430&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@571&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vast+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@571&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@571&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@571&family=Train+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@571&family=Tourney:wght@500&family=Train+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Monoton&family=Montserrat:wght@571&family=Tourney:wght@500&family=Train+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@571;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap");

@media screen and (min-width: 1000px) {
  .resumePageContainer {
    background-color: #21248d;
    top: 0;
    margin-top: 10vh;
    margin-bottom: 5vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .resumePage {
    background-color: #3e42c2;
    display: flex;
    align-items: stretch;
    align-content: stretch;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 50vw;
    max-width: 60vw;
    height: 100%;
    top: 0;
    border-radius: 0;
    color: white;
  }

  .sectionHeader {
    font-weight: 600;
    font-style: normal;
    font-family: "Kanit", sans-serif;
    text-align: center;
    justify-content: center;
    font-size: 2.5rem;
  }

  .jobTitle {
    text-align: left;
    margin-bottom: 0;
  }

  .jobs {
    display: flex;
    flex-direction: column;
    flex: 1 1 70%;
    order: 2;
    align-self: stretch;
    background-color: #3e42c2;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 10px;
    padding: 20px;
  }

  .flipCard {
    display: flex;
    flex-direction: column;
    flex: 1 1 20%;
    order: 1;
    justify-content: top;
    background-color: 3e42c2;
    gap: 20px;
    margin: 0;
    padding-top: 20%;
    padding: 0;
  }
}

@media screen and (max-width: 999px) {
  .resumePageContainer {
    background-color: #21248d;
    top: 0;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .resumePage {
    background-color: #3e42c2;
    display: flex;
    align-items: stretch;
    align-content: stretch;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 100vw;
    max-width: 100vw;
    height: 100%;
    top: 0;
    border-radius: 0;
    color: white;
  }

  .sectionHeader {
    font-weight: 600;
    font-style: normal;
    font-family: "Kanit", sans-serif;
    text-align: center;
    justify-content: center;
    font-size: 2.5rem;
  }

  .jobTitle {
    text-align: left;
    margin-bottom: 0;
  }

  .jobs {
    display: flex;
    flex-direction: column;
    flex: 1 1 70%;
    order: 2;
    align-self: stretch;
    background-color: #3e42c2;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 10px;
    padding: 20px;
  }

  .flipCard {
    display: flex;
    flex-direction: column;
    flex: 1 1 20%;
    order: 1;
    justify-content: top;
    background-color: 3e42c2;
    gap: 20px;
    margin: 0;
    padding-top: 20%;
    padding: 0;
  }
}

/* 
.resumePageContainer {
  background-color: #21248d;
  top: 0;
  margin-top: 10vh;
  margin-bottom: 5vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resumePage {
  background-color: #3e42c2;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 50vw;
  max-width: 60vw;
  height: 100%;
  top: 0;
  border-radius: 0;
  color: white;
}

.sectionHeader {
  font-weight: 600;
  font-style: normal;
  font-family: "Kanit", sans-serif;
  text-align: center;
  justify-content: center;
  font-size: 2.5rem;
}

.jobTitle {
  text-align: left;
  margin-bottom: 0;
}

.jobs {
  display: flex;
  flex-direction: column;
  flex: 1 1 70%;
  align-self: stretch;
  background-color: #3e42c2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin: 10px;
  padding: 20px;
}

.rightSectionPanel {
  display: flex;
  flex-direction: column;
  flex: 1 1 20%;
  align-self: stretch;
  justify-content: top;
  gap: 20px;
  background-color: #35c87e;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin: 0;
  padding-top: 20%;
  padding: 0;
} */

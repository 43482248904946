@import url("https://fonts.googleapis.com/css2?family=Vast+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Nunito:ital,wght@0,900;1,600&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@430&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@571&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vast+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@571&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@571&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@571&family=Train+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@571&family=Tourney:wght@500&family=Train+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Monoton&family=Montserrat:wght@571&family=Tourney:wght@500&family=Train+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@571;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap");

@media screen and (min-width: 600px) {
  .contactPage {
    position: fixed;
    display: flex;
    justify-content: center;
    inset: 0;
    background-color: 255, 255, 255, 0.9;
    backdrop-filter: blur(8px);
  }

  .contactContainer {
    min-width: 600px;
    max-width: 60vw;
    max-height: 60vh;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4ee499;
    padding: 2vw;
    border-radius: 8px;
    color: white;
  }

  h3 {
    text-align: center;
    font-size: 1.75rem;
  }

  form {
    font-family: "Comfortaa", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    flex-direction: column;
    justify-content: center;
  }

  .fields {
    display: flex;
    flex-direction: column;
  }

  input,
  textarea {
    width: 100%;
    max-height: 30vh;
    min-height: 1.5rem;
    margin-bottom: 1rem;
    border-style: none;
    border-radius: 6px;
  }

  label {
    margin-bottom: 0.125rem;
    clear: left;
    text-align: left;
    font-size: 1.2rem;
  }

  #message {
    min-width: 50%;
    min-height: 10vh;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 599px) {
  .contactPage {
    position: fixed;
    display: flex;
    justify-content: center;
    inset: 0;
    background-color: 255, 255, 255, 0.9;
    backdrop-filter: blur(4px);
  }

  .contactContainer {
    min-width: 260px;
    max-width: 60vw;
    min-height: fit-content;
    max-height: 80vh;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4ee499;
    padding: 2vw;
    border-radius: 8px;
    color: white;
    overflow: scroll;
  }

  h3 {
    text-align: center;
    font-size: 1.75rem;
  }

  form {
    font-family: "Comfortaa", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  input,
  textarea :not(input[type="submit"]) {
    min-width: 90%;
    max-width: 90%;
    max-height: 30vh;
    min-height: 1.5rem;
    margin-bottom: 0.5rem;
    margin-left: 3%;
    display: inline-block;
    clear: left;
  }

  label {
    display: inline-block;
    margin-bottom: 0.125rem;
    margin-left: 3%;
    clear: left;
    text-align: left;
  }

  #message {
    min-width: 90%;
    max-width: 90%;
    min-height: 10vh;
  }

  input[type="submit"] {
    background-color: #6100ff;
    font-family: "Montserrat", "Courier New", Courier, monospace;
    font-size: 0.9rem;
    color: #fff;
    border-radius: 16px;
    display: inline-block;
    padding-bottom: 0;
    clear: left;
    margin-left: 5vw;
    border-color: #6100ff;
    min-width: 5rem;
    max-width: 5rem;
    height: 1.5rem;
    text-transform: uppercase;
    margin-top: 0.5rem;
  }

  input[type="submit"]:hover {
    background-color: #ffe36d;
    border-color: #ffe36d;
    color: black;
  }

  /* .closeContact {
    background-color: #e5606a;
    font-family: "Montserrat", "Courier New", Courier, monospace;
    float: left;
    clear: left;
    font-size: 0.9rem;
    color: #fff;
    border-radius: 16px;
    border-color: #e5606a;
    width: 5rem;
    margin-left: 5vw;
    margin-top: 1.5vh;
    height: 1.5rem;
    text-transform: uppercase;
    padding-bottom: 0;
  } */

  .closeContact:hover {
    background-color: #6100ff;
    border-color: #6100ff;
    color: #fff;
  }
}
